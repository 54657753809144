<template>
	<v-container>

		<portal	to="app-bar-title">
			Учні
		</portal>

		<portal to="header-content">

			<v-spacer></v-spacer>

			<v-expanding-field
				v-model="search"
				:hide-details="true"
				prepend-inner-icon="mdi-magnify"
				clearable
				dense
				flat
				solo>
			</v-expanding-field>
		</portal>

		<!-- <v-data-table
			:headers="headers"
			:items="users"
			:items-per-page="20"
			:search="search"
			elevation="0">

			<template v-slot:top>

				<v-toolbar flat>
					<v-spacer/>
					<v-spacer/>
					<v-spacer/>
					<v-text-field
						v-model="search"
						append-icon="search"
						label="Пошук"
						single-line
						hide-details/>
				</v-toolbar>
			</template>

			<template v-slot:item.subjects="{ item }">
				<v-autocomplete
					v-model="item.subjects"
					:items="subjects"
					attach
					chips
					multiple
					dense
					small-chips
					item-value="id"
					item-text="name"
					return-object>
					<template v-slot:selection="data">
						<v-chip
							v-bind="data.attrs"
							:input-value="data.selected"
							close
							color="secondary"
							@click="data.select"
							@click:close="remove(data.item)">
							{{ data.item.name }}
						</v-chip>
					</template>
				</v-autocomplete>
			</template>

			<template v-slot:item.action="{ item }">
				<v-icon
					small
					class="mr-2"
					color="amber"
					@click="edit(item)">
					edit
				</v-icon>
						
				<v-icon
					small
					color="red"
					@click="delete(item)">
					delete
				</v-icon>
			</template>

		</v-data-table> -->

		<!-- <v-btn-toggle
			v-model="filter"
			group
			dense>

			<v-btn
				:value="(user) => user.is('super_admin')"
				class="rounded-lg text-none"
				text>
				Супер адміни
				<v-chip
					color="error"
					class="ml-1"
					x-small>
					10
				</v-chip>
			</v-btn>

			<v-btn
				value="unactive"
				class="rounded-lg text-none"
				text>
				Неактивні
				<v-chip
					color="success"
					class="ml-1"
					x-small>
					7
				</v-chip>
			</v-btn>
		</v-btn-toggle> -->

		<v-data-iterator
			:items="users"
			:items-per-page="10"
			:search="search">

			<template #default="props">
				<v-row>
					<v-col
						v-for="(item, key) in props.items"
						:key="key"
						cols="12"
						sm="6"
						md="4"
						lg="3">
						<v-card class="elevation-24">
							<v-card-text>
								<v-avatar
									class="mb-2"
									size="64">
									<Avatar
										:name="item.full_name"
										variant="beam">
									</Avatar>
								</v-avatar>

								<h2>
									<router-link
										:to="{
											name: 'user',
											params: {
												userid: item.id,
											}
										}">
										{{ item.name }} {{ item.surname }}
									</router-link>
								</h2>
								<p>{{ item.email }}</p>
								<p class="mb-8">
									<v-chip x-small>
										{{ item.created_at | datetime }}
									</v-chip>
								</p>

								<v-select
									v-model="item.roles"
									:items="roles"
									attach
									chips
									small-chips
									dense
									multiple
									item-value="id"
									item-text="name"
									return-object
									validate-on-blur
									label="Роль"
									@change="(val) => updateUserRoles(item.id, val)">
									<template v-slot:selection="data">
										<v-chip
											v-bind="data.attrs"
											:input-value="data.selected"
											small
											color="secondary">
											{{ data.item.name }}
										</v-chip>
									</template>
								</v-select>

								<v-select
									v-model="item.user_subjects"
									:items="subjects"
									attach
									chips
									small-chips
									dense
									multiple
									item-value="id"
									item-text="name"
									return-object
									label="Тести"
									@change="(val) => updateUserSubject(item.id, val)">
									<template v-slot:selection="data">
										<v-chip
											v-bind="data.attrs"
											:input-value="data.selected"
											small>
											{{ data.item.name }}
										</v-chip>
									</template>
								</v-select>

								<v-select
									v-model="item.user_courses"
									:items="courses"
									attach
									chips
									small-chips
									dense
									multiple
									item-value="id"
									item-text="name"
									return-object
									label="Курси"
									@change="(val) => updateUserCourses(item.id, val)">
									<template v-slot:selection="data">
										<v-chip
											v-bind="data.attrs"
											:input-value="data.selected"
											small>
											{{ data.item.name }}
										</v-chip>
									</template>
								</v-select>

								<v-select
									v-model="item.teached_subjects"
									:items="subjects"
									item-value="id"
									item-text="name"
									label="Викладає предмети"
									return-object
									multiple
									attach
									chips
									small-chips
									dense
									@change="() => updateUser(item)">
									<template #selection="data">
										<v-chip
											v-bind="data.attrs"
											:input-value="data.selected"
											small>
											{{ data.item.name }}
										</v-chip>
									</template>
								</v-select>

							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</template>

			<!-- <template v-slot:footer>
				<v-toolbar
				class="mt-2"
				color="indigo"
				dark
				flat
				>
				<v-toolbar-title class="subheading">This is a footer</v-toolbar-title>
				</v-toolbar>
			</template> -->

		</v-data-iterator>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Avatar from '../components/BoringAvatars'

import User from '../models/User'

export default {
	components: {
		Avatar,
	},
	data: () => ({
		headers: [
			{
				text: "Ім'я",
				value: 'name',
			},
			{
				text: 'Прізвище',
				value: 'surname',
			},
			{
				text: 'Предмети',
				value: 'subjects',
			},
		],
		search: '',
	}),
	computed: {
		...mapGetters('subjects', [
			'subjects',
		]),
		...mapGetters('courses', [
			'courses',
		]),
		...mapGetters('roles', [
			'roles',
		]),
		users() {
			return User
				.query()
				.orderBy('created_at', 'desc')
				.get()
		},
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),
		...mapActions('subjects', [
			'loadSubjects',
		]),
		...mapActions('courses', [
			'loadCourses',
		]),
		...mapActions('roles', [
			'loadRoles',
		]),
		...mapActions('users', [
			'updateRoles',
			'updateSubjects',
			'updateCourses',
		]),

		async loadUsers() {
			try {
				await User.fetchGetAll()
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список учнів',
					color: 'error',
				}, { root: true })
			}
		},

		updateUserRoles(userid, roles) {
			roles = roles.map(r => r.id)
			this.updateRoles({ userid, roles })
		},
		updateUserSubject(userid, subjects) {
			subjects = subjects.map(s => s.id)
			this.updateSubjects({ userid, subjects })
		},
		updateUserCourses(userid, courses) {
			courses = courses.map(c => c.id)
			this.updateCourses({ userid, courses })
		},
		async updateUser(user) {
			try {
				await User.fetchUpdate(user)

				this.notify({
					text: 'Користувача успішно оновлено',
					color: 'success',
				}, { root: true })
			} catch (error) {
				this.notify({
					text: 'Не вдалося оновити користувача',
					color: 'error',
				}, { root: true })
				throw error
			}
		},
	},
	mounted() {
		this.loadSubjects()
		this.loadCourses()
		this.loadRoles()
		this.loadUsers()
	},
}
</script>